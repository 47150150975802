import { Button } from "@mui/material";
import React, { useRef, useState } from "react";
export default function ImageEncryptionComponent({ sBox }) {
    const [imageSrc, setImageSrc] = useState(null);
    const canvasRef = useRef(null);
    // Function to handle image loading
    const handleImageLoad = (event) => {
        var _a;
        const file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result); // Load the image into state
            };
            reader.readAsDataURL(file); // Read image as a data URL
        }
    };
    // Function to process the image (increase RGB values)
    const processImage = () => {
        if (!imageSrc || !canvasRef.current) {
            alert("No image to encrypt. Please upload one.");
            return;
        }
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = imageSrc;
        image.onload = () => {
            canvas.width = image.width;
            canvas.height = image.height;
            // Draw the image onto the canvas
            ctx === null || ctx === void 0 ? void 0 : ctx.drawImage(image, 0, 0, image.width, image.height);
            // Get image data (pixels)
            const imageData = ctx === null || ctx === void 0 ? void 0 : ctx.getImageData(0, 0, image.width, image.height);
            const data = imageData === null || imageData === void 0 ? void 0 : imageData.data;
            if (data) {
                // Loop through each pixel and adjust r, g, b values
                for (let i = 0; i < data.length; i += 4) {
                    // Increase each channel by 124, ensuring values don't exceed 255
                    data[i] = sBox[data[i]]; // Red
                    data[i + 1] = sBox[data[i + 1]]; // Green
                    data[i + 2] = sBox[data[i + 2]]; // Blue
                    // data[i + 3] is the alpha channel, so we leave it unchanged
                }
                // Put modified image data back on the canvas
                ctx === null || ctx === void 0 ? void 0 : ctx.putImageData(imageData, 0, 0);
            }
        };
    };
    return (sBox.length ? React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: processImage }, "Encrypt image"),
        React.createElement("input", { type: "file", accept: "image/*", onChange: handleImageLoad }),
        React.createElement("canvas", { ref: canvasRef })) : null);
}
